var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5"}),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-categories-create' },"trash-list-path":"settings-categories-trash-list","can-create":{action: 'change', subject: 'Defaults'},"can-show-trash-list":{action: 'change', subject: 'Defaults'},"table-config-options":_vm.tableConfigOptions()},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"title":_vm.$t('Set Inactive'),"icon":"LTrashIconKits","size":"32"},on:{"click":function($event){return _vm.setInActive(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"title":_vm.$t('View'),"icon":"LPenIconUpdated","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'settings-categories-update', params: { id: data.item.id } }, $event)}}})],1)]}},{key:"cell(products_count)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-block text-center"},[_c('b-link',{staticStyle:{"color":"#646464"},attrs:{"to":{ name: 'settings-categories-products-list' , params: { id: data.item.id }}}},[_vm._v(" "+_vm._s(data.value)+" ")])],1)]}},{key:"cell(id)",fn:function(ref){
var item = ref.data.item;
return [_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"cell(is_active)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(data.value ? 'Active': 'Inactive'))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }