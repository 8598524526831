import { CHECKBOX_LEF, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '10%' } },
    { key: 'name', label: 'Name', thStyle: { width: '20%' } },
    { key: 'products_count', label: 'Associated items', thStyle: { width: '20%', textAlign: 'center' } },
    { key: 'id', label: 'ID', thStyle: { width: '30%', textAlign: 'center' } },
    { key: 'is_active', label: 'Status', thStyle: { width: '20%' } },
  ]
  const tableTrashColumns = [
    { key: 'actions', label: '', thStyle: { width: '10%' } },
    { key: 'id', label: 'ID', thStyle: { width: '10%' } },
    { key: 'name', label: 'Name' },
    { key: 'is_active', label: 'Status' },
  ]
  const productTableColumn = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'model',
      label: 'Model',
    },
    {
      key: 'category',
      label: 'Category',
    },
    {
      key: 'status',
      label: 'Status',
    },
  ]

  const fields = {
    name: {
      type: TEXT_INPUT,
      label: 'Category Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
      rules: 'required',
    },
  }

  return {
    tableColumns,
    tableTrashColumns,
    fields,
    productTableColumn,
  }
}
