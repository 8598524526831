<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      <!--      {{ $t('Categories List') }}-->
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-categories-create' }"
      trash-list-path="settings-categories-trash-list"
      :can-create="{action: 'change', subject: 'Defaults'}"
      :can-show-trash-list="{action: 'change', subject: 'Defaults'}"
      :table-config-options="tableConfigOptions()"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Set Inactive')"
            icon="LTrashIconKits"
            size="32"
            class="mr-1 cursor-pointer"
            @click="setInActive(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('View')"
            icon="LPenIconUpdated"
            size="32"
            class="cursor-pointer"
            @click="gotoNewPage({ name: 'settings-categories-update', params: { id: data.item.id } }, $event)"
          />
        </div>
      </template>
      <template
        #cell(products_count)="{ data }"
        class="d-block text-center"
      >
        <div class="d-block text-center">
          <b-link
            style="color: #646464"
            :to="{ name: 'settings-categories-products-list' , params: { id: data.item.id }}"
          >
            {{ data.value }}
          </b-link>
        </div>
      </template>
      <template #cell(id)="{ data: {item} }">
        <span class="d-block text-center">
          {{ item.id }}
        </span>
      </template>
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip, BLink } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import tableConfig from '../CategoriesConfig'

export default {
  name: 'CategoriesList',
  components: {
    LTable,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters('listModule', ['getStatus']),
  },
  methods: {
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getActiveCategoryList`,
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(category) {
      this.confirmNotification(this, category, `${this.MODULE_NAME}/setInActive`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' }, false)
        .then(() => {
          this.refetchData()
        }).catch(err => {
          this.toastErrorNotification(this, err.response?.data.message, err.response.status)
        })
    },
  },
  setup() {
    const MODULE_NAME = 'settings-catalog-categories'
    const { tableColumns } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
    }
  },
}
</script>
<style lang="scss">
  .product-count {
    display: inline-flex;
    justify-content: center;
    font-weight: bold;
    padding: 3px;
    border-radius: 5px;
    //background: rgba(0, 0, 0, 0.08);
    min-width: 100px;
    border: 1px solid transparent;
    box-sizing: border-box;
    transition: all 200ms ease;

    &:hover {
      border-color: gray;
    }
  }
</style>
